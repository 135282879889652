import React, { createContext, useContext, useMemo, useState } from 'react';

interface Context {
  loading: boolean;
  setLoading: (l: boolean) => void;
}

const SuspenseLoaderContext = createContext<Context>({ loading: false, setLoading: () => {} });

interface SuspenseLoaderProviderProps {
  children?: React.ReactNode;
}

const SuspenseLoaderProvider = ({ children }: SuspenseLoaderProviderProps) => {
  const [loading, setLoading] = useState(false);

  const value = useMemo(
    () => ({
      loading,
      setLoading: (l: boolean) => setLoading(l),
    }),
    [loading, setLoading],
  );

  return <SuspenseLoaderContext.Provider value={value}>{children}</SuspenseLoaderContext.Provider>;
};

export const useSuspensLoader = () => {
  const context = useContext(SuspenseLoaderContext);

  if (!context) {
    throw new Error('useSuspensLoader must be used within a SuspenseLoaderProvider');
  }

  return context;
};

export default SuspenseLoaderProvider;
