import type { QueryClient } from '@tanstack/react-query';

import type { ElasticSearchResponse } from '../../@types/ElasticSearchResponse';
import type { LiveShopping } from '../../@types/LiveShopping';
import type { ApiResponse } from '../../Http';
import type { SSR_NEW } from '../../QueryClientSetup';
import { FEATURED_LIVESHOPPING, LIVE_SHOPPING_QUERY } from './QueryKeys';

const registerSsrLiveShopping = (queryClient: QueryClient, serverState: SSR_NEW) => {
  const list = serverState?.liveShopping?.liveShoppingResult;
  const single = serverState?.liveShopping?.liveShoppingResultBySlug;
  const featured = serverState?.liveShopping?.featuredLiveShopping;

  const initialFeatured: ApiResponse<ElasticSearchResponse<LiveShopping>> | undefined = featured
    ? {
        response: featured,
        successful: true,
      }
    : undefined;

  if (initialFeatured && initialFeatured.response.documentList.documents?.[0]?.slug) {
    queryClient.setQueryData([FEATURED_LIVESHOPPING], initialFeatured);

    queryClient.setQueryData(
      [LIVE_SHOPPING_QUERY, initialFeatured.response.documentList.documents[0].slug],
      initialFeatured,
    );
  }

  const initialData: ApiResponse<ElasticSearchResponse<LiveShopping>> | undefined = list
    ? {
        response: list,
        successful: true,
      }
    : undefined;

  if (initialData) {
    queryClient.setQueryData([LIVE_SHOPPING_QUERY], initialData);
    initialData.response.documentList.documents.forEach((doc) => {
      const initialSingleDataFromList:
        | ApiResponse<ElasticSearchResponse<LiveShopping>>
        | undefined = {
        response: {
          documentList: {
            documents: [doc],
            numberOfHits: 1,
          },
          facets: null,
        },
        successful: true,
      };

      queryClient.setQueryData(
        [LIVE_SHOPPING_QUERY, initialSingleDataFromList.response.documentList.documents?.[0].slug],
        initialSingleDataFromList,
      );
    });
  }

  const initialSingleData: ApiResponse<ElasticSearchResponse<LiveShopping>> | undefined = single
    ? {
        response: single,
        successful: true,
      }
    : undefined;

  if (initialSingleData) {
    const slug = single?.documentList.documents?.[0].slug;
    queryClient.setQueryData([LIVE_SHOPPING_QUERY, slug], initialSingleData);
  }

  return queryClient;
};

export default registerSsrLiveShopping;
