import { requestStatus, responseStatus } from '../shared/statuses';

import { RCV_SEARCH_FAQ, REQ_CLEAR_FAQ, REQ_SEARCH_FAQ } from '../actions/faq';

import type { Faq, FaqCategory } from '../@types/Faq';
import type { Actions } from '../actions';

interface FaqState {
  requestStatus: number;
  responseStatus: number;
  list: Faq[];
  categories: FaqCategory[];
}

export const initialState: FaqState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  list: [],
  categories: [],
};

const faqReducer = (state = initialState, action: Actions): FaqState => {
  switch (action.type) {
    case REQ_SEARCH_FAQ:
      return {
        ...state,
        list: [],
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_SEARCH_FAQ:
      return {
        ...state,
        list: action.response.documentList.documents,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CLEAR_FAQ:
      return { ...initialState };
    default:
      return state;
  }
};

export default faqReducer;
