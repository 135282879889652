import { requestStatus, responseStatus } from '../shared/statuses';

import type {
  Insurance,
  InsuranceProviderResponse,
  InsuranceUserPersisted,
} from '../@types/Insurance';
import type { Actions } from '../actions';
import {
  RCV_FETCH_INSURANCE,
  RCV_INSURANCE_BRANDING,
  RCV_INSURANCE_INQUIRY,
  RCV_INSURANCE_PROVIDER,
  REQUEST_FAILED,
  REQ_CLEAR_INSURANCE,
  REQ_CLEAR_PERSIST_DATA,
  REQ_FETCH_INSURANCE,
  REQ_INSURANCE_BRANDING,
  REQ_INSURANCE_INQUIRY,
  REQ_INSURANCE_PROVIDER,
  REQ_PERSIST_DATA,
} from '../actions/insurance';

const INSURANCE_DATA_KEY = 'wayke-insurance-props';
const setPersistedData = (personalNumber: string, drivingRange: string) => {
  try {
    localStorage.setItem(INSURANCE_DATA_KEY, JSON.stringify({ personalNumber, drivingRange }));
    return { personalNumber, drivingRange };
  } catch (_e) {
    return { personalNumber, drivingRange };
  }
};
const clearPersistedData = () => {
  try {
    localStorage.removeItem(INSURANCE_DATA_KEY);
    return {};
  } catch (_e) {
    return {};
  }
};
const getPersistedData = (): InsuranceUserPersisted => {
  let result: InsuranceUserPersisted = {};

  try {
    const persisted = localStorage.getItem(INSURANCE_DATA_KEY);

    if (persisted) {
      result = JSON.parse(persisted);
    }
  } catch (_e) {
    result = {};
  }

  return result;
};

interface InsurancesState {
  requestStatus: number;
  responseStatus: number;
  list: Insurance[];
  persisted: InsuranceUserPersisted;
  branding: string | null;
  provider: InsuranceProviderResponse | null;
}

export const initialState: InsurancesState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  list: [],
  persisted: getPersistedData(),
  branding: null,
  provider: null,
};

const insurancesReducer = (
  state: InsurancesState = initialState,
  action: Actions,
): InsurancesState => {
  switch (action.type) {
    case REQ_FETCH_INSURANCE:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_FETCH_INSURANCE:
      return {
        ...state,
        list: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_INSURANCE_INQUIRY:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_INSURANCE_INQUIRY:
      return {
        ...state,
        list: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_INSURANCE_PROVIDER:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_INSURANCE_PROVIDER:
      return {
        ...state,
        provider: action.response ? action.response : null,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_INSURANCE_BRANDING:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_INSURANCE_BRANDING:
      return {
        ...state,
        branding: action.response ? action.response.Branding : null,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_PERSIST_DATA:
      return { ...state, persisted: setPersistedData(action.personalNumber, action.drivingRange) };
    case REQ_CLEAR_PERSIST_DATA:
      return { ...state, persisted: clearPersistedData() };
    case REQ_CLEAR_INSURANCE:
      return { ...initialState };
    case REQUEST_FAILED:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default insurancesReducer;
