﻿import { requestStatus, responseStatus } from '../shared/statuses';

import type { Conversation } from '../@types/Conversation';
import type { Actions } from '../actions';
import {
  RCV_CONVERSATION,
  RCV_CONVERSATION_LIST,
  RCV_CREATE_CONVERSATION,
  RCV_UPDATE_CONVERSATION,
  REQUEST_FAILED,
  REQ_CONVERSATION,
  REQ_CONVERSATION_LIST,
  REQ_CREATE_CONVERSATION,
  REQ_UPDATE_CONVERSATION,
  SET_CLEAR_CONVERSATION,
} from '../actions/conversation';
import { RCV_CREATE_MESSAGE } from '../actions/message';

interface ConversationState {
  requestStatus: number;
  responseStatus: number;
  conversations: Conversation[];
  current: Conversation | null;
}

const emptyList: Conversation[] = [];
export const initialState: ConversationState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  conversations: emptyList,
  current: null,
};

const conversationsReducer = (
  state: ConversationState = initialState,
  action: Actions,
): ConversationState => {
  switch (action.type) {
    case REQ_CONVERSATION_LIST:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_CONVERSATION_LIST:
      return {
        ...state,
        conversations: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CONVERSATION:
      return {
        ...state,
        current: null,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_CONVERSATION:
      return {
        ...state,
        current: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CREATE_CONVERSATION:
      return {
        ...state,
        requestStatus: requestStatus.CREATING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_CREATE_CONVERSATION:
      return {
        ...state,
        current: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_UPDATE_CONVERSATION:
      return {
        ...state,
        requestStatus: requestStatus.UPDATING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_UPDATE_CONVERSATION: {
      const list = state.conversations.slice();
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === action.response.id) {
          list[i].unreadMessageCount = action.response.unreadMessageCount;
        }
      }

      return {
        ...state,
        conversations: list,
        current: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    }
    case RCV_CREATE_MESSAGE: {
      const current = state.current ? { ...state.current } : null;
      if (current) {
        if (current.messages) {
          current.messages.push(action.response);
        } else {
          current.messages = [action.response];
        }
      }

      return { ...state, current };
    }
    case SET_CLEAR_CONVERSATION:
      return { ...state, current: null };
    case REQUEST_FAILED:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default conversationsReducer;
