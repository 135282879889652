import { requestStatus, responseStatus } from '../shared/statuses';

import { getPosition, setPosition } from '../utils/location';

import type { GeoNormalizeSearchResult, WaykeLocation } from '../@types/WaykeLocation';
import type { Actions } from '../actions';
import {
  RCV_LOCATION,
  RCV_LOCATION_FROM_POSTAL_CODE,
  RCV_LOCATION_FROM_SEARCH,
  REQUEST_FAILED,
  REQ_CLEAR_LOCATION_SEARCH,
  REQ_LOCATION_FROM_POSTAL_CODE,
  REQ_LOCATION_FROM_SEARCH,
} from '../actions/geo';
import { notEmpty } from '../utils/formats';

interface GeoState {
  requestStatus: number;
  responseStatus: number;
  location: WaykeLocation | null;
  postal: GeoNormalizeSearchResult | null;
  results: WaykeLocation[];
}

export const initialState: GeoState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  location: getPosition(),
  postal: null,
  results: [],
};

const getDefaultAddressComponentName = (components: google.maps.GeocoderAddressComponent[]) => {
  let component = components.find((ac) => ac.types.includes('administrative_area_level_2'));
  component = component ? component : components[0];

  return component.long_name;
};

const getAddressComponentName = (
  components: google.maps.GeocoderAddressComponent[],
  addressType: string,
  fallback: ((components: google.maps.GeocoderAddressComponent[]) => string) | string | null = null,
) => {
  const component = components.find((ac) => ac.types.indexOf(addressType) >= 0);

  if (!component && fallback) return typeof fallback === 'string' ? fallback : fallback(components);

  return component ? component.long_name : null;
};

const normalizeCoords = (coords: google.maps.LatLng) => ({
  lat:
    typeof coords.lat === 'number'
      ? Number.parseFloat((coords.lat as unknown as number).toFixed(2))
      : coords.lat(),
  lng:
    typeof coords.lng === 'number'
      ? Number.parseFloat((coords.lng as unknown as number).toFixed(2))
      : coords.lng(),
});

const normalizeSearchResult = (
  result: google.maps.places.PlaceResult | undefined,
  radius: number | undefined | null = null,
  addressType = 'locality',
  shouldNormalizeCoords = true,
): WaykeLocation | null => {
  try {
    const location = result?.geometry?.location;
    const address_components = result?.address_components;

    if (!location || !address_components || !result?.formatted_address) return null;
    const city = getAddressComponentName(
      address_components,
      addressType,
      getDefaultAddressComponentName,
    );
    const name = city || result.formatted_address;
    const coords = shouldNormalizeCoords
      ? normalizeCoords(location)
      : { lat: location.lat(), lng: location.lng() };

    return {
      ...coords,
      city,
      name,
      region: getAddressComponentName(address_components, 'administrative_area_level_1'),
      radius: radius || 50,
    };
  } catch (_e) {
    return null;
  }
};

const locationReducer = (state: GeoState = initialState, action: Actions): GeoState => {
  switch (action.type) {
    case RCV_LOCATION:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
        location: setPosition(normalizeSearchResult(action.response, action.radius)),
      };
    case REQ_LOCATION_FROM_POSTAL_CODE:
      return {
        ...state,
        location: null,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_LOCATION_FROM_POSTAL_CODE:
      return {
        ...state,
        postal: setPosition(normalizeSearchResult(action.response, null, 'postal_town')),
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_LOCATION_FROM_SEARCH:
      return {
        ...state,
        results: [],
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_LOCATION_FROM_SEARCH:
      return {
        ...state,
        results: action.response.map((result) => normalizeSearchResult(result)).filter(notEmpty),
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CLEAR_LOCATION_SEARCH:
      return { ...state, results: [] };
    case REQUEST_FAILED:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default locationReducer;
