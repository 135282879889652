import type { Actions } from '../actions';
import {
  CLEAR_LIVE_SHOPPING_REFERENCE,
  SET_LIVE_SHOPPING_REFERENCE,
} from '../actions/liveshopping';

interface LiveshoppingReference {
  reference?: string;
}

export const initialState: LiveshoppingReference = {};

const liveshoppingReducer = (
  state: LiveshoppingReference = initialState,
  action: Actions,
): LiveshoppingReference => {
  switch (action.type) {
    case SET_LIVE_SHOPPING_REFERENCE:
      return { ...state, reference: action.reference };
    case CLEAR_LIVE_SHOPPING_REFERENCE:
      return { ...state, reference: undefined };
    default:
      return state;
  }
};

export default liveshoppingReducer;
