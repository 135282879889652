import { requestStatus, responseStatus } from '../shared/statuses';

import type { ContentPage, ContentPageNavigationItem } from '../@types/ContentPage';
import type { DocumentList } from '../@types/ElasticSearchResponse';
import type { Actions } from '../actions';
import {
  RCV_CONTENTPAGE,
  RCV_CONTENTPAGE_LIST,
  RCV_CONTENTPAGE_NAVIGATION,
  REQUEST_FAILED,
  REQ_CONTENTPAGE,
  REQ_CONTENTPAGE_LIST,
  REQ_CONTENTPAGE_NAVIGATION,
} from '../actions/contentpage';

interface ContentPagesState {
  requestStatus: number;
  responseStatus: number;
  navRequestStatus: number;
  navResponseStatus: number;
  documentList: DocumentList<ContentPage> | null;
  single: DocumentList<ContentPage> | null;
  notFound: boolean;
  navigation?: ContentPageNavigationItem[] | null;
}

export const initialState: ContentPagesState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  navRequestStatus: requestStatus.WAITING,
  navResponseStatus: responseStatus.OK,
  documentList: null,
  single: null,
  notFound: false,
};

const contentpagesReducer = (
  state: ContentPagesState = initialState,
  action: Actions,
): ContentPagesState => {
  switch (action.type) {
    case REQ_CONTENTPAGE_LIST:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_CONTENTPAGE_LIST:
      return {
        ...state,
        documentList: action.response.documentList,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CONTENTPAGE:
      return {
        ...state,
        single: null,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_CONTENTPAGE:
      return {
        ...state,
        single: action.response.documentList,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CONTENTPAGE_NAVIGATION:
      return {
        ...state,
        navigation: null,
        navRequestStatus: requestStatus.FETCHING,
        navResponseStatus: responseStatus.PENDING,
      };
    case RCV_CONTENTPAGE_NAVIGATION:
      return {
        ...state,
        navigation: action.response,
        navRequestStatus: requestStatus.WAITING,
        navResponseStatus: responseStatus.OK,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        single: null,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default contentpagesReducer;
