import React from 'react';
import { useSelector } from 'react-redux';

import { getProductListSortVisible } from '../../selectors/events';

const Imbox = () => {
  const productListSortVisible = useSelector(getProductListSortVisible);
  return (
    <div data-am-imbox="" className={productListSortVisible ? 'list-utility-open' : ''}>
      <div className="wayke-imbox" id="imbox-container" />
    </div>
  );
};

export default Imbox;
