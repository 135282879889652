import { requestStatus, responseStatus } from '../shared/statuses';

import type { ProfileSettings } from '../@types/ProfileSettings';
import type { Actions } from '../actions';
import {
  RCV_FETCH_SETTINGS,
  RCV_UPDATE_SETTINGS,
  REQUEST_FAILED,
  REQ_FETCH_SETTINGS,
  REQ_UPDATE_SETTINGS,
} from '../actions/settings';

interface SettingsState {
  requestStatus: number;
  responseStatus: number;
  current: ProfileSettings | null;
}

export const initialState: SettingsState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  current: null,
};

const settingsReducer = (state = initialState, action: Actions): SettingsState => {
  switch (action.type) {
    case REQ_FETCH_SETTINGS:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_FETCH_SETTINGS:
      return {
        ...state,
        current: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_UPDATE_SETTINGS:
      return {
        ...state,
        requestStatus: requestStatus.UPDATING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_UPDATE_SETTINGS:
      return {
        ...state,
        current: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQUEST_FAILED:
      return { ...initialState, responseStatus: responseStatus.FAILED };
    default:
      return state;
  }
};

export default settingsReducer;
