import { requestStatus, responseStatus } from '../shared/statuses';

import type { DocumentList } from '../@types/ElasticSearchResponse';
import type { WaykeList } from '../@types/List';
import type { Actions } from '../actions';
import {
  ERR_LISTS,
  ERR_LIST_HITS,
  RCV_LISTS,
  RCV_LIST_HITS,
  REQ_LISTS,
  REQ_LIST_HITS,
} from '../actions/list';

interface ListState {
  requestStatus: number;
  responseStatus: number;
  requestStatusHits: number;
  responseStatusHits: number;
  documentList: DocumentList<WaykeList> | null;
  hits: { [key: string]: number | undefined } | null;
}

export const initialState: ListState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  requestStatusHits: requestStatus.WAITING,
  responseStatusHits: responseStatus.OK,
  documentList: null,
  hits: null,
};

const listReducer = (state: ListState = initialState, action: Actions): ListState => {
  switch (action.type) {
    case REQ_LISTS:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
        documentList: null,
      };
    case RCV_LISTS:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.PENDING,
        documentList: action.response.documentList,
      };
    case ERR_LISTS:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    case REQ_LIST_HITS:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_LIST_HITS:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.PENDING,
        hits: action.response,
      };
    case ERR_LIST_HITS:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default listReducer;
