import type { SubscriptionAdvertisement } from '../@types/AdvertismentSubscription';
import type { Actions } from '../actions';
import {
  RCV_SHARED_SUBSCRIPTION_NAMESPACE,
  REQUEST_FAILED,
  REQ_SHARED_SUBSCRIPTION_NAMESPACE,
} from '../actions/ad-subscription-namespaces';
import { requestStatus, responseStatus } from '../shared/statuses';

interface SharedUserContentState {
  requestStatus: number;
  responseStatus: number;
  sharedNamespaceAdvertisements: SubscriptionAdvertisement[];
}

export const initialState: SharedUserContentState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  sharedNamespaceAdvertisements: [],
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case REQ_SHARED_SUBSCRIPTION_NAMESPACE:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_SHARED_SUBSCRIPTION_NAMESPACE:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
        sharedNamespaceAdvertisements: action.response,
      };
    case REQUEST_FAILED:
      if (action.action !== REQ_SHARED_SUBSCRIPTION_NAMESPACE) return state;
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};
