import type { QueryClient } from '@tanstack/react-query';
import type { ArticleCategory } from '../../@types/ArticleCategory';
import type { Article } from '../../@types/ArticleV2';
import type { ElasticSearchResponse } from '../../@types/ElasticSearchResponse';
import type { ApiResponse } from '../../Http';
import type { SSR_NEW, SSR_OLD } from '../../QueryClientSetup';
import { getArticlesURLSearchParams, getCategory } from '../../routes/articles/utils';
import { ARTICLES_QUERY, ARTICLE_CATEGORIES_QUERY } from './QueryKeys';

const registerSsrArticles = (
  queryClient: QueryClient,
  ssr: SSR_NEW,
  serverState: SSR_OLD,
  search: string,
) => {
  const categories = ssr.articles?.categories;
  const initialDataCategories: ApiResponse<ArticleCategory[]> | undefined = categories?.length
    ? {
        response: categories,
        successful: true,
      }
    : undefined;

  if (initialDataCategories) {
    queryClient.setQueryData([ARTICLE_CATEGORIES_QUERY], initialDataCategories);
  }

  const category = getCategory(categories, serverState.routing?.path);
  const params = getArticlesURLSearchParams(category, search);

  const documentList = ssr.articles?.result?.documentList;
  const initialData: ApiResponse<ElasticSearchResponse<Article>> | undefined = documentList
    ? {
        response: {
          facets: [],
          documentList: {
            ...documentList,
          },
        },
        successful: true,
      }
    : undefined;

  if (initialData) {
    queryClient.setQueryData([ARTICLES_QUERY, params.toString()], initialData);
  }
  return queryClient;
};

export default registerSsrArticles;
