import React from 'react';
import { useSelector } from 'react-redux';

import { getSearchDocument } from '../../utils/reselectors';
import AdvertisementFooterLinkCloud from './advertisement-footer-link-cloud';
import DefaultFooterLinkCloud from './default-footer-link-cloud';

interface IFooterLinkCloudSelector {
  pathname: string;
}

const FooterLinkCloudSelector = ({ pathname }: IFooterLinkCloudSelector) => {
  const item = useSelector(getSearchDocument);

  if (
    pathname.includes('/salj-din-bil') ||
    pathname.includes('/salj-till-handlare') ||
    pathname.includes('/selg-til-forhandler')
  )
    return null;

  if (pathname === '/') return <DefaultFooterLinkCloud />;

  if (pathname.includes('/objekt') && item) return <AdvertisementFooterLinkCloud />;

  return null;
};

export default FooterLinkCloudSelector;
