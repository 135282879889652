import styled, { keyframes } from 'styled-components';

import { size } from '../../layout/helpers';

export const PageLoader = styled.div.withConfig({ componentId: 'sc-1htjcmz-0' })`
    --_w: 6vw;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${size(0.5)};
    z-index: ${(props) => props.theme.zIndex.pageLoader};

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: calc(var(--_w) * -1);
        width: var(--_w);
        height: 100%;
        background: var(--c-primary-bg);
        animation: ${keyframes`
            25% {
                transform: scaleX(400%);
            }
            50% {
                transform: scaleX(none);
            }
            60%,
            100% {
                left: 100%;
            }
        `} 800ms ease infinite;
    }
`;
