import React from 'react';

import type { FooterLinkSection } from '../../shared/footer-links';
import FooterSubLink from './footer-sub-link';

interface FooterLinkProps {
  linkSection: FooterLinkSection;
}

const FooterLink = ({ linkSection }: FooterLinkProps) => (
  <div className="col-sm-6 col-md-3 m-b">
    <div className="footer-heading">{linkSection.displayName}</div>
    <ul data-am-list="">
      {linkSection.links.map((footerLink) => (
        <li className="m-b-mini" key={footerLink.displayName}>
          <FooterSubLink footerLink={footerLink} />
        </li>
      ))}
    </ul>
  </div>
);

export default FooterLink;
