import type { Dispatch as ReduxDispatch } from '@reduxjs/toolkit';

import type { Lead } from '../@types/Lead';
import type { BaseAction } from '../@types/actions';
import * as http from '../Http';
import { buildAuthorizedPostRequest } from '../Http/requestTemplates';
import type { RootState } from '../reducers';
import requestCache from '../utils/request-cache';

export const REQ_CREATE_LEAD = 'REQ/CREATE_LEAD';
export const RCV_CREATE_LEAD = 'RCV/CREATE_LEAD';
export const REQUEST_FAILED = 'LEAD/REQUEST_FAILED';
type REQ_CREATE_LEAD_TYPE = BaseAction<typeof REQ_CREATE_LEAD>;
type RCV_CREATE_LEAD_TYPE = BaseAction<typeof RCV_CREATE_LEAD>;
type REQUEST_FAILED_TYPE = BaseAction<typeof REQUEST_FAILED>;

export type LEAD_ACTION = REQ_CREATE_LEAD_TYPE | RCV_CREATE_LEAD_TYPE | REQUEST_FAILED_TYPE;

type Dispatch = ReduxDispatch<LEAD_ACTION>;

export const create = (lead: Lead) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { auth } = getState();
  if (!auth.token || requestCache.isPending(REQ_CREATE_LEAD)) {
    return;
  }
  requestCache.isPending(REQ_CREATE_LEAD, true);

  try {
    dispatch({ type: REQ_CREATE_LEAD });

    const response = await http.json<Lead>(
      '/api/leads',
      buildAuthorizedPostRequest(auth.token, { content: lead }),
    );
    http.validateResponse(response);
    dispatch({
      type: RCV_CREATE_LEAD,
    });
  } catch (_e) {
    dispatch({ type: REQUEST_FAILED });
  }
  requestCache.isPending(REQ_CREATE_LEAD, false);
};
