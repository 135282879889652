import type { Facet } from '../@types/ElasticSearchResponse';
import type { Actions } from '../actions';
import {
  REQ_CLOSE_FILTER,
  REQ_CLOSE_FOLDOUT,
  REQ_HIDE_FILTER_BAR,
  REQ_OPEN_FILTER,
  REQ_OPEN_FOLDOUT,
  REQ_SHOW_FILTER_BAR,
} from '../actions/filters';

const deleteBodyCssClass = () => {
  if (!process.env.SSR) {
    window.scrollBy(0, Math.abs(Number.parseInt(document.body.style.top, 10)));
    document.body.style.top = '';
  }
};

const addBodyCssClass = (offset: number) => {
  if (!process.env.SSR) {
    document.body.style.top = `-${offset}px`;
  }
};

interface FiltersState {
  foldoutOpen: boolean;
  facet: Facet | null;
  isExcludedFacet: boolean;
  showFilterBar: boolean;
}

export const initialState: FiltersState = {
  foldoutOpen: false,
  facet: null,
  isExcludedFacet: false,
  showFilterBar: false,
};

const filtersReducer = (state: FiltersState = initialState, action: Actions): FiltersState => {
  switch (action.type) {
    case REQ_OPEN_FOLDOUT:
      addBodyCssClass(action.offset);
      return {
        ...state,
        foldoutOpen: true,
        facet: null,
        isExcludedFacet: false,
        showFilterBar: false,
      };
    case REQ_CLOSE_FOLDOUT:
      deleteBodyCssClass();

      return {
        ...state,
        foldoutOpen: false,
        facet: null,
        isExcludedFacet: false,
        showFilterBar: false,
      };
    case REQ_OPEN_FILTER:
      addBodyCssClass(action.offset);

      return {
        ...state,
        foldoutOpen: true,
        facet: action.facet,
        isExcludedFacet: action.isExcludedFacet,
        showFilterBar: false,
      };
    case REQ_CLOSE_FILTER:
      deleteBodyCssClass();

      return {
        ...state,
        foldoutOpen: true,
        facet: null,
        isExcludedFacet: false,
        showFilterBar: false,
      };
    case REQ_SHOW_FILTER_BAR:
      return { ...state, showFilterBar: true };
    case REQ_HIDE_FILTER_BAR:
      return { ...state, showFilterBar: false };
    default:
      return state;
  }
};

export default filtersReducer;
