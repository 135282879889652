import React from 'react';
import type { FooterLink } from '../../shared/footer-links';
import Link from '../link';

interface FooterLinkProps {
  footerLink: FooterLink;
}

const FooterSubLink = ({ footerLink }: FooterLinkProps) => {
  if (footerLink.externalLink) {
    return (
      <a
        href={footerLink.url}
        className="footer-link"
        title={footerLink.displayName}
        rel="noopener noreferrer"
        target="_blank"
      >
        {footerLink.displayName}
      </a>
    );
  }

  return (
    <Link to={footerLink.url} className="footer-link" title={footerLink.displayName}>
      {footerLink.displayName}
    </Link>
  );
};

export default FooterSubLink;
