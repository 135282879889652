import type { SearchHistory } from '../@types/SearchHistory';
import type { Actions } from '../actions';
import { REQ_SEARCH_HISTORY } from '../actions/history';

interface HistoryState {
  list: SearchHistory[];
}

export const initialState = {
  list: [],
};

const historyReducer = (state: HistoryState = initialState, action: Actions): HistoryState => {
  switch (action.type) {
    case REQ_SEARCH_HISTORY:
      return { list: action.history };
    default:
      return state;
  }
};

export default historyReducer;
