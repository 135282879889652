import { requestStatus, responseStatus } from '../shared/statuses';

import {
  RCV_MEDIA_LIST,
  RCV_REMOVE_MEDIA,
  RCV_SORT_MEDIA,
  RCV_UPLOAD_MEDIA,
  REQUEST_FAILED,
  REQ_CLEAR_UPLOADS,
  REQ_MEDIA_LIST,
  REQ_REMOVE_MEDIA,
  REQ_SORT_MEDIA,
  REQ_STAGE_REMOVED,
  REQ_STAGE_SORT,
  REQ_UPLOAD_MEDIA,
} from '../actions/media';

import type { ConnectedMedia, Media, MediaSortOrder, MediaUpload } from '../@types/Media';
import type { Actions } from '../actions';
import type { MediaListItem } from '../routes/assignments/Components/media-selections';

interface MediaState {
  requestStatus: number;
  responseStatus: number;
  uploads: MediaUpload[];
  connected: { [key: string]: ConnectedMedia[] | undefined };
  stagedSort: MediaSortOrder[];
  stagedRemoved: (Media | MediaListItem)[];
  currentyUploadingCount: number;
}

export const initialState: MediaState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  uploads: [],
  connected: {},
  stagedSort: [],
  stagedRemoved: [],
  currentyUploadingCount: 0,
};

let connected;
let currentyUploadingCount;
const mediaReducer = (state: MediaState = initialState, action: Actions): MediaState => {
  let uploads = null;
  let stagedRemoved: (Media | MediaListItem)[] | null = null;
  switch (action.type) {
    case REQ_MEDIA_LIST:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_MEDIA_LIST:
      connected = { ...state.connected };
      connected[action.advertisement.id] = action.response;
      return {
        ...state,
        connected,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_UPLOAD_MEDIA:
      return {
        ...state,
        currentyUploadingCount: state.currentyUploadingCount + 1,
        requestStatus: requestStatus.CREATING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_UPLOAD_MEDIA:
      uploads = state.uploads.slice();
      uploads.push({
        id: action.response.mediaId,
        url: action.response.url,
        sortOrder: action.sortOrder,
        file: action.file,
      });

      currentyUploadingCount = state.currentyUploadingCount;
      if (currentyUploadingCount > 0) {
        currentyUploadingCount--;
      }

      return {
        ...state,
        currentyUploadingCount,
        uploads,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_REMOVE_MEDIA:
      return {
        ...state,
        requestStatus: requestStatus.REMOVING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_REMOVE_MEDIA: {
      uploads = state.uploads.slice();
      stagedRemoved = state.stagedRemoved.slice();
      const index = uploads.findIndex((m) => m.id === action.id);
      const indexStaged = stagedRemoved.findIndex((sr) => sr.id === action.id);

      if (index >= 0) uploads.splice(index, 1);

      if (indexStaged >= 0) stagedRemoved.splice(indexStaged, 1);

      return {
        ...state,
        uploads,
        stagedRemoved,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    }
    case REQ_SORT_MEDIA:
      return {
        ...state,
        requestStatus: requestStatus.UPDATING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_SORT_MEDIA:
      return {
        ...state,
        stagedSort: [],
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_CLEAR_UPLOADS:
      return { ...state, uploads: [] };
    case REQ_STAGE_SORT:
      return { ...state, stagedSort: action.media };
    case REQ_STAGE_REMOVED:
      stagedRemoved = state.stagedRemoved.slice();
      stagedRemoved.push(action.media);
      return { ...state, stagedRemoved };
    case REQUEST_FAILED:
      currentyUploadingCount = state.currentyUploadingCount;
      if (action.action === REQ_UPLOAD_MEDIA && currentyUploadingCount > 0) {
        currentyUploadingCount--;
      }

      return {
        ...state,
        currentyUploadingCount,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default mediaReducer;
