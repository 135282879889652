import { requestStatus, responseStatus } from '../shared/statuses';

import type { Actions } from '../actions';
import { RCV_CREATE_LEAD, REQUEST_FAILED, REQ_CREATE_LEAD } from '../actions/lead';

interface LeadState {
  requestStatus: number;
  responseStatus: number;
}

export const initialState: LeadState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
};

const leadReducer = (state: LeadState = initialState, action: Actions): LeadState => {
  switch (action.type) {
    case REQ_CREATE_LEAD:
      return { requestStatus: requestStatus.CREATING, responseStatus: responseStatus.PENDING };
    case RCV_CREATE_LEAD:
      return { requestStatus: requestStatus.WAITING, responseStatus: responseStatus.OK };
    case REQUEST_FAILED:
      return { requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
    default:
      return state;
  }
};

export default leadReducer;
