import type { QueryClient } from '@tanstack/react-query';
import type { ElasticSearchResponse } from '../../../@types/ElasticSearchResponse';
import type { Faq } from '../../../@types/Faq';
import type { FaqCategory } from '../../../@types/FaqCategory';
import type { ApiResponse } from '../../../Http';
import type { SSR_OLD } from '../../../QueryClientSetup';
import { getCategory, getFaqsURLSearchParams } from '../utils';
import { FAQ_CATEGORIES_QUERY, FAQ_QUERY } from './QueryKeys';

const getQuery = (params: URLSearchParams) =>
  typeof Buffer !== 'undefined'
    ? Buffer.from(params.toString()).toString('base64')
    : btoa(params.toString());

const registerSsrFaq = (queryClient: QueryClient, serverState: SSR_OLD, search: string) => {
  const categories = serverState.faq?.categories;
  const initialDataCategories: ApiResponse<ElasticSearchResponse<FaqCategory>> | undefined =
    categories
      ? {
          response: categories,
          successful: true,
        }
      : undefined;

  if (initialDataCategories) {
    queryClient.setQueryData([FAQ_CATEGORIES_QUERY], initialDataCategories);
  }

  const category = getCategory(categories?.documentList.documents, serverState.routing?.path);
  const params = getFaqsURLSearchParams(category, search);

  const faqs = serverState.faq?.faqs;
  const initialData: ApiResponse<ElasticSearchResponse<Faq>> | undefined = faqs
    ? {
        response: faqs,
        successful: true,
      }
    : undefined;

  if (initialData) {
    queryClient.setQueryData([FAQ_QUERY, getQuery(params)], initialData);
  }
  return queryClient;
};

export default registerSsrFaq;
