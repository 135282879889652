import { requestStatus, responseStatus } from '../shared/statuses';

import type { FinancialInformation } from '../@types/finance';
import type { Actions } from '../actions';
import {
  RCV_GET_DEFAULT_FINANCE_INFORMATION,
  REQUEST_FAILED,
  REQ_GET_DEFAULT_FINANCE_INFORMATION,
  RESET_DEFAULT_FINANCE_INFORMATION,
} from '../actions/default-financial-information';

interface DefaultFinancialInformationState {
  requestStatus: number;
  responseStatus: number;
  default: FinancialInformation | null;
}

export const initialState: DefaultFinancialInformationState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  default: null,
};

const defaultFinancialInformationReducer = (
  state: DefaultFinancialInformationState = initialState,
  action: Actions,
): DefaultFinancialInformationState => {
  switch (action.type) {
    case RESET_DEFAULT_FINANCE_INFORMATION:
      return {
        default: null,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQ_GET_DEFAULT_FINANCE_INFORMATION:
      return {
        default: null,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_GET_DEFAULT_FINANCE_INFORMATION:
      return {
        default: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQUEST_FAILED:
      return {
        default: null,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default defaultFinancialInformationReducer;
