import type { Actions } from '../actions';
import { RCV_ROUTING } from '../actions/routing';

interface RoutingState {
  path: string;
  from?: string | null;
  isErrorPage?: boolean;
}

const initialState: RoutingState = {
  path: !process.env.SSR ? window.location.pathname : '/',
  from: null,
};

const routingReducer = (state = initialState, action: Actions): RoutingState => {
  switch (action.type) {
    case RCV_ROUTING:
      if (state.path === action.path) {
        return state;
      }
      return { path: action.path, from: state.path };
    default:
      return state;
  }
};

export default routingReducer;
