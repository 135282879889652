import { requestStatus, responseStatus } from '../shared/statuses';

import type { FinancialInformation } from '../@types/finance';
import type { Actions } from '../actions';
import {
  RCV_GET_CURRENT_FINANCE_INFORMATION,
  REQUEST_FAILED,
  REQ_GET_CURRENT_FINANCE_INFORMATION,
  RESET_CURRENT_FINANCE_INFORMATION,
} from '../actions/current-financial-information';

interface CurrentFinancialInformationState {
  requestStatus: number;
  responseStatus: number;
  current: FinancialInformation | null;
}

export const initialState: CurrentFinancialInformationState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  current: null,
};

const currentFinancialInformationReducer = (
  state: CurrentFinancialInformationState = initialState,
  action: Actions,
): CurrentFinancialInformationState => {
  switch (action.type) {
    case REQ_GET_CURRENT_FINANCE_INFORMATION:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_GET_CURRENT_FINANCE_INFORMATION:
      return {
        ...state,
        current: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case RESET_CURRENT_FINANCE_INFORMATION:
      return { ...initialState };
    case REQUEST_FAILED:
      return {
        ...state,
        current: null,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default currentFinancialInformationReducer;
