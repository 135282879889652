﻿import { requestStatus, responseStatus } from '../shared/statuses';

import type { Actions } from '../actions';
import { RCV_CREATE_MESSAGE, REQUEST_FAILED, REQ_CREATE_MESSAGE } from '../actions/message';

interface MessagesState {
  requestStatus: number;
  responseStatus: number;
}

export const initialState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
};

const messagesReducer = (state: MessagesState = initialState, action: Actions): MessagesState => {
  switch (action.type) {
    case REQ_CREATE_MESSAGE:
      return { requestStatus: requestStatus.CREATING, responseStatus: responseStatus.PENDING };
    case RCV_CREATE_MESSAGE:
      return { requestStatus: requestStatus.WAITING, responseStatus: responseStatus.OK };
    case REQUEST_FAILED:
      return { requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
    default:
      return state;
  }
};

export default messagesReducer;
