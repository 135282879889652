import { requestStatus, responseStatus } from '../shared/statuses';

import type { ElasticSearchResponse } from '../@types/ElasticSearchResponse';
import type { SafetyAddon } from '../@types/SafetyAddon';
import type { Actions } from '../actions';
import {
  RCV_SAFETY_ADDONS_LIST,
  REQUEST_FAILED,
  REQ_SAFETY_ADDONS_LIST,
} from '../actions/safety-addons';

interface SafetyAddonState {
  requestStatus: number;
  responseStatus: number;
  list?: ElasticSearchResponse<SafetyAddon>;
}

export const initialState: SafetyAddonState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
};

const safetyAddonsReducer = (
  state: SafetyAddonState = initialState,
  action: Actions,
): SafetyAddonState => {
  switch (action.type) {
    case REQ_SAFETY_ADDONS_LIST:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_SAFETY_ADDONS_LIST:
      return {
        ...state,
        list: action.response,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default safetyAddonsReducer;
