import { css } from 'styled-components';
import { customPropertiesColors } from './colors';

export const customProperties = css`
    :root {
        ${customPropertiesColors};

        // Font Weight
        --font-weight-body: var(--font-weight-light);
        --font-weight-strong: var(--font-weight-medium);
        --font-weight-link: var(--font-weight-regular);

        // Font Size
        // Responsive font sizes changing based on the viewport width
        --font-size-xsmall: 0.625rem; // 10px
        --font-size-small: 0.75rem; // 12px
        --font-size-regular: 0.875rem; // 14px
        --font-size-large: 1rem; // 16px
        --font-size-xlarge: 1.125rem; // 18px

        // Static Font Size
        // Fixed font sizes that do not change based on the viewport width
        --font-size-xxsmall-static: 0.625rem; // 10px
        --font-size-xsmall-static: 0.75rem; // 12px
        --font-size-small-static: 0.875rem; // 14px
        --font-size-regular-static: 1rem; // 16px
        --font-size-large-static: 1.125rem; // 18px

        // Input
        --font-size-input: max(16px, 1rem);

        // Heading Font Size
        --font-size-h1: 1.5rem; // 24px
        --font-size-h2: 1.25rem; // 20px
        --font-size-h3: 1.125rem; // 18px
        --font-size-h4: 1rem; // 16px
        --font-size-h5: 0.875rem; // 14px
        --font-size-h6: 0.5rem; // 8px

        // Line Height
        --line-height-main: 1.5;
        --line-height-thin: 1.2;

        // Line Height Font Size
        --line-height-xsmall: 0.75rem; // 12px
        --line-height-small: 1rem; // 16px
        --line-height-small-static: 1rem; // 16px
        --line-height-regular: 1.25rem; // 20px
        --line-height-large-static: 1.5rem; // 24px
        --line-height-xlarge: 1.5rem; // 24px

        // Heading Line Height
        --line-height-h1: 2rem; // 32px
        --line-height-h2: 1.5rem; // 24px
        --line-height-h3: 1.375rem; // 22px
        --line-height-h4: 1.25rem; // 20px
        --line-height-h5: 1.25rem; // 20px
        --line-height-h6: 0.875rem; // 14px

        --drop-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.03), 0 2px 5px rgba(0, 0, 0, 0.1);

        --layer-autocomplete-foldout: 50;

        --header-height: 5rem; // 80px

        ${(props) => props.theme.breakpoint.LtMd} {
            --header-height: 4.375rem; // 70px
        }

        // Custom properties specific to the market NO
        ${(props) => props.theme.brand.driveRoot} {
            --font-weight-link: var(--font-weight-medium);
        }

        ${(props) => props.theme.breakpoint.Md} {
            --font-size-xsmall: 0.75rem; // 12px
            --font-size-small: 0.875rem; // 14px
            --font-size-regular: 1rem; // 16px
            --font-size-large: 1.125rem; // 18px
            --font-size-xlarge: 1.25rem; // 20px

            --font-size-h1: 2.5rem; // 40px
            --font-size-h2: 2rem; // 32px
            --font-size-h3: 1.5rem; // 24px
            --font-size-h4: 1.125rem; // 18px
            --font-size-h5: 1rem; // 16px
            --font-size-h6: 0.625rem; // 10px

            --line-height-xsmall: 1rem; // 16px
            --line-height-small: 1.25rem; // 20px
            --line-height-regular: 1.5rem; // 24px

            --line-height-h1: 3rem; // 48px
            --line-height-h2: 2.5rem; // 40px
            --line-height-h3: 2rem; // 32px
            --line-height-h4: 1.5rem; // 24px
            --line-height-h5: 1.5rem; // 24px
            --line-height-h6: 1rem; // 16px
        }
    }
`;
