import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Link from '../link';

import getFooterLinks from '../../shared/footer-links';

import { getMarket } from '../../i18n';
import AssignmentWidget from '../../routes/assignments/CreateAssignment/Widget';
import { resolveAssignmentRoute, resolveCarValuationRoute } from '../../routes/utils';
import { Container } from '../../styled-components/components/Container';
import FooterInfoContainer from './footer-info-container';
import FooterLink from './footer-link';
import FooterLinkCloudSelector from './footer-link-cloud-selector';
import FooterSocialMedia from './footer-social-media';

const Footer = () => {
  const { t } = useTranslation('footer');
  const { pathname } = useLocation();
  const footerLinks = getFooterLinks();
  const { marketCode } = getMarket();

  const onOpenConsent = !process.env.SSR ? () => window?.OneTrust?.ToggleInfoDisplay() : undefined;

  return (
    <>
      {!pathname.includes(`/${resolveCarValuationRoute()}`) &&
        !pathname.includes(`/${resolveAssignmentRoute()}`) &&
        !pathname.includes('/profil') &&
        !pathname.includes('/objekt') && (
          <Container style={{ marginBottom: '32px' }}>
            <AssignmentWidget
              variant="fullWidth"
              trackingContext={{
                type: 'widget',
                placement: 'footer',
              }}
            />
          </Container>
        )}

      <footer data-am-footer="">
        <FooterLinkCloudSelector pathname={pathname} />
        <div className="footer-nav">
          <div className="container">
            <div className="row">
              {footerLinks.map((linkSection) => (
                <FooterLink key={linkSection.displayName} linkSection={linkSection} />
              ))}
              <FooterSocialMedia />
            </div>
            <FooterInfoContainer market={marketCode} />
            <hr className="m-y" />
            <div data-am-columnrow="">
              <div className="column">
                <span className="footer-text-small">
                  {t('Responsible publisher', { ns: 'footer' })}
                </span>
              </div>
              <div className="column valign-bottom">
                <div>
                  <button
                    type="button"
                    className="footer-link-small"
                    onClick={onOpenConsent}
                    title={t('Privacy settings', { ns: 'footer' })}
                  >
                    {t('Privacy settings', { ns: 'footer' })}
                  </button>
                  <Link
                    to={
                      marketCode === 'NO'
                        ? '/personvernerklaering-drive-mobility-as'
                        : '/personuppgiftspolicy-wayke'
                    }
                    title={t('Personal data management', { ns: 'footer' })}
                    className="footer-link-small"
                  >
                    {t('Personal data management', { ns: 'footer' })}
                  </Link>
                  <Link
                    to={marketCode === 'NO' ? '/brukervilkar' : '/anvaendarvillkor'}
                    title={t('Terms of Use', { ns: 'footer' })}
                    className="footer-link-small"
                  >
                    {t('Terms of Use', { ns: 'footer' })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
