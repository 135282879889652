import { requestStatus, responseStatus } from '../shared/statuses';

import type { Branding } from '../@types/branding';
import type { Actions } from '../actions';
import { RCV_BRANDING_LIST, REQUEST_FAILED, REQ_BRANDING_LIST } from '../actions/branding';

interface BrandingsState {
  requestStatus: number;
  responseStatus: number;
  list: Branding[];
}

const emptyList: Branding[] = [];
export const initialState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  list: emptyList,
};

const brandingsReducer = (
  state: BrandingsState = initialState,
  action: Actions,
): BrandingsState => {
  switch (action.type) {
    case REQ_BRANDING_LIST:
      return {
        list: emptyList,
        requestStatus: requestStatus.FETCHING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_BRANDING_LIST:
      return {
        list: action.response.documentList.documents,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case REQUEST_FAILED:
      return {
        list: emptyList,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };
    default:
      return state;
  }
};

export default brandingsReducer;
