import type Item from '../@types/item';
import type { RelatedItemCollection } from '../@types/item';
import type { Actions } from '../actions';

import { ERR_MISSING_ITEM, RCV_MISSING_ITEM, REQ_MISSING_ITEM } from '../actions/missing-item';

interface MissingItemState {
  id: string;
  mostSimilarItems: Item[];
  similarLink: string;
  relatedItemsCollections: RelatedItemCollection[];
  isFetching: boolean;
  hasError: boolean;
}

const initialState: MissingItemState = {
  id: '',
  mostSimilarItems: [],
  similarLink: '',
  relatedItemsCollections: [],
  isFetching: false,
  hasError: false,
};

const missingItemReducer = (
  state: MissingItemState = initialState,
  action: Actions,
): MissingItemState => {
  switch (action.type) {
    case REQ_MISSING_ITEM:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        id: action.id,
      };
    case RCV_MISSING_ITEM:
      return {
        ...state,
        isFetching: false,
        mostSimilarItems: action.response.mostSimilarItems,
        similarLink: action.response.similarLink,
        relatedItemsCollections: action.response.relatedItemsCollections,
      };
    case ERR_MISSING_ITEM:
      return {
        ...state,
        isFetching: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default missingItemReducer;
