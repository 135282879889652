import { QueryClient } from '@tanstack/react-query';

import type { ArticleCategory } from '../@types/ArticleCategory';
import type { Article, ArticleAuthor } from '../@types/ArticleV2';
import type { ElasticSearchResponse } from '../@types/ElasticSearchResponse';
import type { Faq } from '../@types/Faq';
import type { FaqCategory } from '../@types/FaqCategory';
import type { LiveShopping } from '../@types/LiveShopping';
import type { MarketCode } from '../@types/MarketCode';
import type { Seo } from '../@types/Seo';
import registerSsrArticle from '../Data/Article/registerSsrArticle';
import registerSsrArticles from '../Data/Article/registerSsrArticles';
import registerSsrArticlesSearch from '../Data/Article/registerSsrArticlesSearch';
import registerSsrAuthor from '../Data/Author/registerSsrAuthor';
import registerSsrLiveShopping from '../Data/Liveshopping/registerSsrLiveShopping';
import registerSsrFaq from '../routes/customer-service/Data/registerSsrFaq';
import registerSsrSeo from '../routes/customer-service/Data/registerSsrSeo';
import registerSsrArticlesStartPage from '../routes/startpage/ArticlesSection/Data/registerSsrArticlesStartPage';

export interface SSR_OLD {
  routing?: {
    path: string;
  };
  articles?: {
    categories?: ArticleCategory[];
    result?: ElasticSearchResponse<Article>;
    single?: ElasticSearchResponse<Article>;
    latestTestDriveArticle?: ElasticSearchResponse<Article>;
  };
  faq?: {
    categories?: ElasticSearchResponse<FaqCategory>;
    faqs: ElasticSearchResponse<Faq>;
  };
}

export interface SSR_NEW {
  liveShopping?: {
    featuredLiveShopping?: ElasticSearchResponse<LiveShopping>;
    liveShoppingResult?: ElasticSearchResponse<LiveShopping>;
    liveShoppingResultBySlug?: ElasticSearchResponse<LiveShopping>;
  };
  articles?: {
    categories?: ArticleCategory[];
    result?: ElasticSearchResponse<Article>;
    singleResult?: ElasticSearchResponse<Article>;
    latestTestDriveArticle?: ElasticSearchResponse<Article>;
  };
  seo?: {
    search: Seo;
  };
  author?: {
    result?: ElasticSearchResponse<ArticleAuthor>;
  };
}

let serverStateOld: SSR_OLD = {};
try {
  serverStateOld = { ...window.__INITIAL__STATE__ } as SSR_OLD;
} catch (_e) {
  serverStateOld = {};
}

let serverState: SSR_NEW = {};
try {
  serverState = { ...window.__WAYKE_SSR__ } as SSR_NEW;
} catch (_e) {
  serverState = {};
}

const QueryClientSetup = (
  ssr: SSR_NEW = serverState,
  initialState: SSR_OLD = serverStateOld,
  search: string,
  marketCode: MarketCode,
) => {
  const queryClient = new QueryClient();

  registerSsrArticle(queryClient, ssr);
  registerSsrArticles(queryClient, ssr, initialState, search);
  registerSsrAuthor(queryClient, ssr);
  registerSsrFaq(queryClient, initialState, search);
  registerSsrArticlesStartPage(queryClient, ssr, marketCode);
  registerSsrArticlesSearch(queryClient, ssr, search);
  registerSsrLiveShopping(queryClient, ssr);
  registerSsrSeo(queryClient, ssr);

  return queryClient;
};

export default QueryClientSetup;
