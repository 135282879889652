﻿/* Other imports */
import '../stylesheets/default.scss';

import { QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { Route, Router, Switch } from 'react-router-dom';

import QueryClientSetup from './QueryClientSetup';
import configureStore from './shared/store';
import Fonts from './styled-components/layout/fonts';
import GlobalStyle from './styled-components/layout/global-style';
import theme from './styled-components/layout/theme';

import { getAccessToken } from './actions/auth';
import { init } from './actions/event';
import { facets } from './actions/search';
import { auth, phonenumbers, pubsub } from './utils';

import App from './components/app/app';
import { routes } from './routes';

import { track } from './actions/tracking';
import history from './history';
import getI18n, { getMarket } from './i18n';
import SuspenseLoaderProvider from './providers/SuspenseLoaderProvider';
import routingSetup from './routing-setup';
import { LiveShopping } from './shared/tracking';
import SuspenseLoader from './suspense-loader';

const queryClient = QueryClientSetup(undefined, undefined, location.search, getMarket().marketCode);
const store = configureStore();

interface BabumserEvent {
  detail: {
    data: {
      interactionType?: string;
      showId?: string;
      timeline?: {
        isLive: boolean;
      };
    };
  };
}

type BabumserEventType = Event & BabumserEvent;

window.addEventListener(
  'load',
  () => {
    if (window && window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(() => {
        window.googletag?.pubads().enableSingleRequest();
        window.googletag?.pubads().collapseEmptyDivs();
        window.googletag?.pubads().setCentering(true);
        window.googletag?.enableServices();
      });
    }

    const node = document.querySelector('#wayke-web-ui');
    if (node) {
      const i18n = getI18n(window.__WAYKE_MARKET__);
      const callback = () => {
        const { dispatch, getState } = store;

        routingSetup(dispatch);
        auth.createRefreshTokenHandler(dispatch, getState);
        getAccessToken()(dispatch);

        facets()(dispatch);

        pubsub.attach(window, dispatch);
        phonenumbers.initialize();

        init()(dispatch, getState);
        window.addEventListener('bambuser-liveshop-tracking-point', (event) => {
          const { detail } = event as BabumserEventType;
          if (detail.data.interactionType) {
            track(LiveShopping.LIVE_SHOPPING_INTERACTION, {
              information: {
                showId: detail?.data?.showId,
                interactionType: detail?.data?.interactionType,
                isLive: detail?.data?.timeline?.isLive,
              },
            })(dispatch, getState);
          }
        });
      };

      const market = getMarket();
      const _routes = routes();
      hydrateRoot(
        node,
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <SuspenseLoaderProvider>
                  <Router history={history}>
                    <Fonts market={market.marketCode} />
                    <GlobalStyle />
                    <App callback={callback} market={market.marketCode}>
                      <Suspense fallback={<SuspenseLoader />}>
                        <Switch>
                          {Object.keys(_routes).map((key) => (
                            <Route key={key} path={key} {..._routes[key]} />
                          ))}
                        </Switch>
                      </Suspense>
                    </App>
                  </Router>
                </SuspenseLoaderProvider>
              </Provider>
            </QueryClientProvider>
          </I18nextProvider>
        </ThemeProvider>,
      );
    }
  },
  true,
);
