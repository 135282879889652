import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { bindActionCreators } from '@reduxjs/toolkit';
import { get as getProfile } from '../../../actions/auth';
import {
  allAndCollectSubscriptions,
  all as getAllSubscriptions,
} from '../../../actions/search-subscription';
import { cdn } from '../../../helpers/urls';
import { getMarket } from '../../../i18n';
import type { RootState } from '../../../reducers';
import { isAuthenticated } from '../../../selectors/auth';
import { getConversations } from '../../../selectors/conversation';
import { getProfile as getProfileSelector } from '../../../selectors/profile';
import { requestStatus } from '../../../shared/statuses';
import { getSearchSubscriptionsListWithCategoryNewHits } from '../../../utils/reselectors';
import { Container } from '../Container';
import Foldout from '../Foldout';
import { IconClose, IconMenu, IconSearch, IconUser } from '../Icon';
import { Skeleton } from '../Skeleton';
import { SrOnly } from '../SrOnly';
import {
  FoldoutMenu,
  FoldoutMenuLink,
  FoldoutMenuLinkText,
  Inner,
  Logo,
  LogoLink,
  Main,
  MainNavItem,
  MainNavLink,
  MainNavList,
  MenuLinkBadge,
  MenuList,
  QuickActionFluid,
  QuickActionFluidText,
  QuickActionMenuClose,
  QuickActionMenuOpen,
  QuickActionMenuWrapper,
  QuickActionProfile,
  QuickActionProfileImage,
  QuickActions,
  Wrapper,
} from './wrapper';

const resolveProfileMyCarsRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return 'mine-biler';
    default:
      return 'mina-bilar';
  }
};

const resolveSubsriptionRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return 'overvakninger';
    default:
      return 'bevakningar';
  }
};

const resolveProfileMessagesRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return 'meldinger';
    default:
      return 'meddelanden';
  }
};

const resolveProfileAdvertisements = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return 'mine-salgsforesporseler';
    default:
      return 'mina-annonser';
  }
};

export interface HeaderLink {
  path: string;
  title: string;
  target?: string;
  rel?: string;
  displayName: string;
  b2b?: boolean;
  external?: boolean;
  hideInMainNav?: boolean;
}

export interface MainNavigation {
  ariaLabel: string;
  links: HeaderLink[];
}

export interface SecondaryNavigation {
  links: HeaderLink[];
}

interface HeaderProps {
  variant?: 'transparent';
  logo: React.ReactNode;
  homeLink: HeaderLink;
  mainNavigation?: MainNavigation;
  secondaryNavigation?: SecondaryNavigation;
}

const Header = ({ variant, logo, homeLink, mainNavigation, secondaryNavigation }: HeaderProps) => {
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const authenticating = useSelector<RootState, boolean>(
    (s) => s.auth.requestStatus === requestStatus.FETCHING,
  );
  const authenticated = useSelector(isAuthenticated);
  const conversations = useSelector(getConversations);
  const searchSubscriptionNewHits = useSelector(getSearchSubscriptionsListWithCategoryNewHits);
  const profile = useSelector(getProfileSelector);
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const { push, location } = useHistory();

  const actions = bindActionCreators(
    { getProfile, allAndCollectSubscriptions, getAllSubscriptions },
    dispatch,
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const onOpenMenu = () => setMenuOpen(true);
  const onCloseMenu = () => setMenuOpen(false);

  const [profileFoldout, setProfileFoldout] = useState(false);
  const onOpenProfileFoldout = () => setProfileFoldout(true);
  const onCloseProfileFoldout = () => setProfileFoldout(false);

  const newMessagesCount = conversations.reduce((sum, next) => sum + next.unreadMessageCount, 0);

  const onClickProfileFoldoutItem = useCallback((route: string) => {
    push(route);
    onCloseProfileFoldout();
  }, []);

  const onClickOutsideHeader = useCallback((e: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      onCloseMenu();
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      actions.getProfile();
      actions.allAndCollectSubscriptions();
      actions.getAllSubscriptions();
    }
  }, [authenticated]);

  useEffect(() => {
    if (menuOpen) {
      window.addEventListener('click', onClickOutsideHeader);
    } else {
      window.removeEventListener('click', onClickOutsideHeader);
    }
  }, [menuOpen]);

  useEffect(() => {
    onCloseMenu();
  }, [location.pathname]);

  return (
    <Wrapper $variant={variant} $menuOpen={menuOpen} ref={wrapperRef}>
      <Container>
        <Inner>
          <Logo>
            <LogoLink
              to={homeLink.path}
              title={homeLink.title}
              target={homeLink.target}
              rel={homeLink.rel}
            >
              {logo}
              <SrOnly>{homeLink.displayName}</SrOnly>
            </LogoLink>
          </Logo>
          <Main>
            {mainNavigation && (
              <MainNavList aria-label={mainNavigation.ariaLabel}>
                {mainNavigation?.links?.map(
                  (link, i) =>
                    !link.hideInMainNav && (
                      <MainNavItem key={`${link.path}-${i}`}>
                        <MainNavLink
                          to={link.path}
                          title={link.title}
                          target={link.target}
                          rel={link.rel}
                          onClick={onCloseMenu}
                        >
                          {link.displayName}
                        </MainNavLink>
                      </MainNavItem>
                    ),
                )}
              </MainNavList>
            )}
            <QuickActions>
              <QuickActionFluid as={Link} to="/sok" title={t('Search vehicle', { ns: 'header' })}>
                <IconSearch />
                <QuickActionFluidText>{t('Search vehicle', { ns: 'header' })}</QuickActionFluidText>
              </QuickActionFluid>
              {profile ? (
                <Foldout
                  onClose={onCloseProfileFoldout}
                  isOpen={profileFoldout}
                  posX="end"
                  items={[
                    [
                      {
                        displayName: t('My profile', { ns: 'header' }),
                        title: t('My profile', { ns: 'header' }),
                        onClick: () => onClickProfileFoldoutItem('/profil'),
                      },
                      {
                        displayName: t('My cars', { ns: 'header' }),
                        title: t('My cars', { ns: 'header' }),
                        onClick: () =>
                          onClickProfileFoldoutItem(`/profil/${resolveProfileMyCarsRoute()}`),
                      },
                      {
                        displayName: t('Messages', { ns: 'header' }),
                        title: t('Messages', { ns: 'header' }),
                        onClick: () =>
                          onClickProfileFoldoutItem(`/profil/${resolveProfileMessagesRoute()}`),
                        notificationCount: newMessagesCount > 0 ? newMessagesCount : undefined,
                      },
                      {
                        displayName: t('Lists & Monitoring', { ns: 'header' }),
                        title: t('Lists & Monitoring', { ns: 'header' }),
                        onClick: () =>
                          onClickProfileFoldoutItem(`/profil/${resolveSubsriptionRoute()}`),
                        notificationCount:
                          searchSubscriptionNewHits > 0 ? searchSubscriptionNewHits : undefined,
                      },
                      {
                        displayName: t('My ads', { ns: 'header' }),
                        title: t('My ads', { ns: 'header' }),
                        onClick: () =>
                          onClickProfileFoldoutItem(`/profil/${resolveProfileAdvertisements()}`),
                      },
                      {
                        displayName: t('Settings', { ns: 'header' }),
                        title: t('Settings', { ns: 'header' }),
                        onClick: () => onClickProfileFoldoutItem('/profil/konto'),
                      },
                    ],
                    [
                      {
                        displayName: t('Log out', { ns: 'header' }),
                        title: t('Log out', { ns: 'header' }),
                        onClick: () => {
                          const l = document.createElement('a');
                          l.href = '/auth/signout';
                          l.click();
                        },
                        tone: 'link',
                      },
                    ],
                  ]}
                >
                  <QuickActionProfile
                    onClick={onOpenProfileFoldout}
                    title={t('My profile', { ns: 'header' }) || ''}
                    $hasNotifications={newMessagesCount > 0 || searchSubscriptionNewHits > 0}
                  >
                    {profile && profile.image ? (
                      <QuickActionProfileImage
                        src={`${cdn(profile.image)}?w=40&h40`}
                        srcSet={`${cdn(profile.image)}?w=80&h80 2x`}
                        alt={
                          t("User's profile picture", {
                            ns: 'header',
                            name: `${profile.givenName} ${profile.familyName}`,
                          }) || ''
                        }
                      />
                    ) : (
                      <IconUser />
                    )}
                    <SrOnly as="span">{t('My profile', { ns: 'header' })}</SrOnly>
                  </QuickActionProfile>
                </Foldout>
              ) : authenticating ? (
                <Skeleton $variant="circle" $width="40px" $height="40px" />
              ) : (
                <QuickActionProfile
                  as="a"
                  href={`/auth/signin?redirectUrl=${pathname}`}
                  title={t('Log in or register', { ns: 'header' }) || ''}
                >
                  <IconUser />
                  <SrOnly as="span">{t('Log in or register', { ns: 'header' }) || ''}</SrOnly>
                </QuickActionProfile>
              )}
              <QuickActionMenuWrapper $menuOpen={menuOpen}>
                <QuickActionMenuOpen
                  onClick={onOpenMenu}
                  title={t('Open menu', { ns: 'header' }) || ''}
                >
                  <IconMenu />
                  <SrOnly as="span">{t('Open menu', { ns: 'header' }) || ''}</SrOnly>
                </QuickActionMenuOpen>
                <QuickActionMenuClose
                  onClick={onCloseMenu}
                  title={t('Close menu', { ns: 'header' }) || ''}
                >
                  <IconClose />
                  <SrOnly as="span">{t('Close menu', { ns: 'header' }) || ''}</SrOnly>
                </QuickActionMenuClose>
              </QuickActionMenuWrapper>
            </QuickActions>
          </Main>
        </Inner>
      </Container>
      {menuOpen && (
        <FoldoutMenu>
          <Container>
            <MenuList>
              {mainNavigation?.links?.map((link) => (
                <FoldoutMenuLink key={link.path} to={link.path} $large title={link.title}>
                  <FoldoutMenuLinkText>{link.displayName}</FoldoutMenuLinkText>
                </FoldoutMenuLink>
              ))}
            </MenuList>
            <MenuList>
              {secondaryNavigation?.links.map((link) => (
                <FoldoutMenuLink
                  key={link.path}
                  as={link.external ? 'a' : Link}
                  target={link.external ? '_blank' : undefined}
                  to={link.path}
                  href={link.path}
                  title={link.title}
                >
                  <FoldoutMenuLinkText>{link.displayName}</FoldoutMenuLinkText>
                  {link.b2b && <MenuLinkBadge>{t('B2B', { ns: 'header' })}</MenuLinkBadge>}
                </FoldoutMenuLink>
              ))}
            </MenuList>
          </Container>
        </FoldoutMenu>
      )}
    </Wrapper>
  );
};

export default Header;
