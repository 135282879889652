import { requestStatus, responseStatus } from '../shared/statuses';

import type { AssignmentDraft } from '../@types/assignment';
import type { Actions } from '../actions';
import {
  ERR_ASSIGNMENT_DRAFT,
  ERR_ASSIGNMENT_DRAFTS_LIST,
  ERR_CREATE_ASSIGNMENT_DRAFT,
  ERR_PUBLISH_ASSIGNMENT_DRAFT,
  ERR_UPDATE_ASSIGNMENT_DRAFT,
  RCV_ASSIGNMENT_DRAFT,
  RCV_ASSIGNMENT_DRAFTS_LIST,
  RCV_CREATE_ASSIGNMENT_DRAFT,
  RCV_PUBLISH_ASSIGNMENT_DRAFT,
  RCV_REMOVE_ASSIGNMENT_DRAFT,
  RCV_UPDATE_ASSIGNMENT_DRAFT,
  REQ_ASSIGNMENT_DRAFT,
  REQ_ASSIGNMENT_DRAFTS_LIST,
  REQ_CLEAR_CREATED_ASSIGNMENT_DRAFT,
  REQ_CREATE_ASSIGNMENT_DRAFT,
  REQ_PUBLISH_ASSIGNMENT_DRAFT,
  REQ_REMOVE_ASSIGNMENT_DRAFT,
  REQ_SET_UNSAVED,
  REQ_UPDATE_ASSIGNMENT_DRAFT,
} from '../actions/assignment-draft';

interface AssignmentDraftsState {
  requestStatus: number;
  responseStatus: number;
  requestStatusPublishDraft: number;
  responseStatusPublishDraft: number;
  requestStatusUpdateDraft: number;
  responseStatusUpdateDraft: number;
  requestStatusLoadDraft: number;
  responseStatusLoadDraft: number;
  documentList?: AssignmentDraft[];
  single?: AssignmentDraft;
  createdDraftId?: string;
  unsaved: boolean;
  assignmentDraft?: AssignmentDraft;
}

export const initialState: AssignmentDraftsState = {
  requestStatus: requestStatus.WAITING,
  responseStatus: responseStatus.OK,
  requestStatusPublishDraft: requestStatus.WAITING,
  responseStatusPublishDraft: responseStatus.OK,
  requestStatusUpdateDraft: requestStatus.WAITING,
  responseStatusUpdateDraft: responseStatus.OK,
  requestStatusLoadDraft: requestStatus.WAITING,
  responseStatusLoadDraft: responseStatus.OK,
  unsaved: false,
};

const assignmentDraftsReducer = (
  state: AssignmentDraftsState = initialState,
  action: Actions,
): AssignmentDraftsState => {
  let list = null;
  let index = -1;

  switch (action.type) {
    case REQ_ASSIGNMENT_DRAFTS_LIST:
      return {
        ...state,
        requestStatus: requestStatus.FETCHING_LIST,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_ASSIGNMENT_DRAFTS_LIST:
      return {
        ...state,
        documentList: action.response.drafts,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case ERR_ASSIGNMENT_DRAFTS_LIST:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };

    case REQ_SET_UNSAVED:
      return { ...state, unsaved: action.unsaved };

    case REQ_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatusLoadDraft: requestStatus.FETCHING_LIST,
        responseStatusLoadDraft: responseStatus.PENDING,
      };
    case RCV_ASSIGNMENT_DRAFT:
      return {
        ...state,
        single: action.response,
        requestStatusLoadDraft: requestStatus.WAITING,
        responseStatusLoadDraft: responseStatus.OK,
      };
    case ERR_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };

    case REQ_CREATE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatus: requestStatus.CREATING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_CREATE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        createdDraftId: action.response.waykeId,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };
    case ERR_CREATE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };

    case REQ_PUBLISH_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatusPublishDraft: requestStatus.CREATING,
        responseStatusPublishDraft: responseStatus.PENDING,
      };
    case RCV_PUBLISH_ASSIGNMENT_DRAFT:
      return {
        ...state,
        assignmentDraft: action.response,
        requestStatusPublishDraft: requestStatus.WAITING,
        responseStatusPublishDraft: responseStatus.OK,
      };
    case ERR_PUBLISH_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.FAILED,
      };

    case REQ_UPDATE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatusUpdateDraft: requestStatus.UPDATING,
        responseStatusUpdateDraft: responseStatus.PENDING,
      };
    case RCV_UPDATE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatusUpdateDraft: requestStatus.WAITING,
        responseStatusUpdateDraft: responseStatus.OK,
      };
    case ERR_UPDATE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatusUpdateDraft: requestStatus.WAITING,
        responseStatusUpdateDraft: responseStatus.FAILED,
      };
    case REQ_REMOVE_ASSIGNMENT_DRAFT:
      return {
        ...state,
        requestStatus: requestStatus.REMOVING,
        responseStatus: responseStatus.PENDING,
      };
    case RCV_REMOVE_ASSIGNMENT_DRAFT:
      list = [...(state.documentList || [])];

      index = list.findIndex((d) => d.waykeId === action.response);
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...state,
        documentList: list,
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
      };

    case REQ_CLEAR_CREATED_ASSIGNMENT_DRAFT:
      return { ...state, assignmentDraft: undefined };

    default:
      return state;
  }
};

export default assignmentDraftsReducer;
