import type { QueryClient } from '@tanstack/react-query';
import type { ArticleAuthor } from '../../@types/ArticleV2';
import type { ElasticSearchResponse } from '../../@types/ElasticSearchResponse';
import type { ApiResponse } from '../../Http';
import type { SSR_NEW } from '../../QueryClientSetup';

const registerSsrAuthor = (queryClient: QueryClient, serverState: SSR_NEW) => {
  const state = serverState.author?.result?.documentList;
  const initialData: ApiResponse<ElasticSearchResponse<ArticleAuthor>> | undefined = state
    ? {
        response: {
          facets: [],
          documentList: {
            ...state,
          },
        },
        successful: true,
      }
    : undefined;

  if (initialData) {
    const slug = initialData.response.documentList.documents?.[0]?.authorSlug;
    queryClient.setQueryData([`/api/author/${slug}`], initialData);
  }

  return queryClient;
};

export default registerSsrAuthor;
