import type { QueryClient } from '@tanstack/react-query';
import type { Article } from '../../../../@types/ArticleV2';
import type { ElasticSearchResponse } from '../../../../@types/ElasticSearchResponse';
import type { MarketCode } from '../../../../@types/MarketCode';
import { ARTICLES_QUERY } from '../../../../Data/Article/QueryKeys';
import type { ApiResponse } from '../../../../Http';
import type { SSR_NEW } from '../../../../QueryClientSetup';
import { getLatestTestDriveArticleURLSearchParams } from '../utils';

const registerSsrArticlesStartPage = (
  queryClient: QueryClient,
  serverState: SSR_NEW,
  marketCode: MarketCode,
) => {
  const state = serverState.articles?.latestTestDriveArticle;

  const initialData: ApiResponse<ElasticSearchResponse<Article>> | undefined = state
    ? {
        response: state,
        successful: true,
      }
    : undefined;

  if (initialData) {
    const params = getLatestTestDriveArticleURLSearchParams(marketCode);
    queryClient.setQueryData([ARTICLES_QUERY, params.toString()], initialData);
  }

  return queryClient;
};

export default registerSsrArticlesStartPage;
