﻿import React, { lazy } from 'react';
import { getMarket } from '../i18n';

const Startpage = lazy(() => import('./startpage'));

const Search = lazy(() => import('./search/components/search'));
const SearchHistory = lazy(() => import('./search/components/search-history'));
const Item = lazy(() => import('./item/item'));
const Articles = lazy(() => import('./articles'));
const Article = lazy(() => import('./article'));
const PopulateAssignment = lazy(() => import('../routes/assignments/PopulateAssignment'));
const PublishedAssignment = lazy(() => import('../routes/assignments/PublishedAssignment'));
const CreateAssignment = lazy(() => import('../routes/assignments/CreateAssignment'));
const CarValuation = lazy(() => import('../routes/car-valuation'));
const CarValuationPreview = lazy(() => import('../routes/car-valuation-preview'));
const RetailerList = lazy(() => import('./retailers'));
const Retailer = lazy(() => import('./retailer'));
const Faq = lazy(() => import('./customer-service/faq'));
const CustomerService = lazy(() => import('./customer-service'));
const ContentPageSelector = lazy(() => import('./contentpages/components/content-page-selector'));
const SharedSubscriptionList = lazy(() => import('./shared/subscription-list'));
const Profile = lazy(() => import('./profile/profile'));
const NamespacedAdvertisement = lazy(() => import('./list/namespaced-advertisement'));
const SearchSubscription = lazy(() => import('./list/search-subscription'));
const LiveShopping = lazy(() => import('./live-shopping'));
const LiveShoppingSingle = lazy(() => import('./live-shopping/LiveShoppingSingle'));
const AuthorPage = lazy(() => import('./author'));
const ArticleSearch = lazy(() => import('./articles/ArticleSearch'));
const ErrorView = lazy(() => import('./error'));

interface RouteProps {
  match: { params: { [key: string]: string | undefined } };
}

interface Routes {
  [key: string]:
    | {
        component: (props: RouteProps) => React.JSX.Element;
        exact?: boolean;
      }
    | undefined;
}

export const routesSE: Routes = {
  '/': { exact: true, component: () => <Startpage /> },
  '/sok/historik': { component: () => <SearchHistory /> },
  '/sok/:filters?': { component: () => <Search /> },
  '/salj-till-handlare/publicerad': { component: () => <PublishedAssignment /> },
  '/salj-till-handlare/skapa/:id?': {
    component: ({ match }) => <PopulateAssignment draftId={match.params.id!} />,
  },
  '/salj-till-handlare': { component: () => <CreateAssignment /> },
  '/vardera-bil/:registrationNumber/:odometerReadingValue': {
    component: ({ match }) => (
      <CarValuationPreview
        registrationNumber={match.params.registrationNumber!}
        odometerReadingValue={match.params.odometerReadingValue}
      />
    ),
  },
  '/vardera-bil/:registrationNumber': {
    component: ({ match }) => (
      <CarValuationPreview registrationNumber={match.params.registrationNumber!} />
    ),
  },
  '/vardera-bil': { component: () => <CarValuation /> },
  '/objekt/:id/:description': { component: ({ match }) => <Item id={match.params.id!} /> },
  '/artiklar/:category/:slug': {
    component: ({ match }) => (
      <Article category={match.params.category!} slug={match.params.slug!} />
    ),
  },
  '/artiklar/sok': { component: () => <ArticleSearch /> },
  '/artiklar/:category?': {
    component: ({ match }) => <Articles category={match.params.category} />,
  },
  '/aterforsaljare/:slug': { component: ({ match }) => <Retailer slug={match.params.slug!} /> },
  '/aterforsaljare': { component: () => <RetailerList /> },
  '/listor/sparade-annonser/:id': {
    component: ({ match }) => <NamespacedAdvertisement id={match.params.id!} />,
  },
  '/listor/bevakade-sokningar/:id': {
    component: ({ match }) => (
      <SearchSubscription id={match.params.id ? match.params.id.split(/[?#]/)[0] : undefined} />
    ),
  },
  '/profil/mina-annonser': { component: () => <Profile viewName="advertisements" /> },
  '/profil/konto': { component: () => <Profile viewName="account" /> },
  '/profil/meddelanden/:id': {
    component: ({ match }) => <Profile viewName="conversations" id={match.params.id} />,
  },
  '/profil/meddelanden': { component: () => <Profile viewName="conversations" /> },
  '/profil/bevakningar': { component: () => <Profile viewName="subscriptions" /> },
  '/profil/mina-bilar/:id': {
    component: ({ match }) => <Profile viewName="my-car" id={match.params.id} />,
  },
  '/profil/mina-bilar': { component: () => <Profile viewName="my-cars" /> },
  '/profil': { component: () => <Profile viewName="profile" /> },
  '/delat/bevakningslista/:id': {
    component: ({ match }) => <SharedSubscriptionList id={match.params.id!} />,
  },
  '/kundservice/:category/:slug': {
    component: ({ match }) => <Faq category={match.params.category} slug={match.params.slug} />,
  },
  '/kundservice/:category': { component: ({ match }) => <Faq category={match.params.category} /> },
  '/kundservice': { component: () => <CustomerService /> },
  '/liveshopping/:slug': {
    component: ({ match }) =>
      getMarket().marketCode === 'SE' ? (
        <LiveShoppingSingle slug={match.params.slug!} />
      ) : (
        <ErrorView />
      ),
  },
  '/liveshopping': { component: () => <LiveShopping /> },
  '/skribenter/:slug': {
    component: ({ match }) => <AuthorPage slug={match.params.slug!} />,
  },
  '/:parent/:child': {
    component: ({ match }) => <ContentPageSelector slug={match.params.child!} />,
  },
  '/:parent': { component: ({ match }) => <ContentPageSelector slug={match.params.parent!} /> },
};

const routesNO: Routes = {
  '/': { exact: true, component: () => <Startpage /> },
  '/sok/historik': { component: () => <SearchHistory /> },
  '/sok/:filters?': { component: () => <Search /> },
  '/selg-til-forhandler/publisert': { component: () => <PublishedAssignment /> },
  '/selg-til-forhandler/opprett/:id?': {
    component: ({ match }) => <PopulateAssignment draftId={match.params.id!} />,
  },
  '/selg-til-forhandler': { component: () => <CreateAssignment /> },
  '/verdivurdere-bil/:registrationNumber/:odometerReadingValue': {
    component: ({ match }) => (
      <CarValuationPreview
        registrationNumber={match.params.registrationNumber!}
        odometerReadingValue={match.params.odometerReadingValue}
      />
    ),
  },
  '/verdivurdere-bil/:registrationNumber': {
    component: ({ match }) => (
      <CarValuationPreview registrationNumber={match.params.registrationNumber!} />
    ),
  },
  '/verdivurdere-bil': { component: () => <CarValuation /> },
  '/objekt/:id/:description': { component: ({ match }) => <Item id={match.params.id!} /> },
  '/artikler/:category/:slug': {
    component: ({ match }) => (
      <Article category={match.params.category!} slug={match.params.slug!} />
    ),
  },
  '/artikler/sok': { component: () => <ArticleSearch /> },
  '/artikler/:category?': {
    component: ({ match }) => <Articles category={match.params.category} />,
  },
  '/forhandler/:slug': { component: ({ match }) => <Retailer slug={match.params.slug!} /> },
  '/forhandler': { component: () => <RetailerList /> },
  '/lister/lagrede-annonser/:id': {
    component: ({ match }) => <NamespacedAdvertisement id={match.params.id!} />,
  },
  '/lister/overvaket-sok/:id': {
    component: ({ match }) => (
      <SearchSubscription id={match.params.id ? match.params.id.split(/[?#]/)[0] : undefined} />
    ),
  },
  '/profil/mine-salgsforesporseler': { component: () => <Profile viewName="advertisements" /> },
  '/profil/konto': { component: () => <Profile viewName="account" /> },
  '/profil/meldinger/:id': {
    component: ({ match }) => <Profile viewName="conversations" id={match.params.id} />,
  },
  '/profil/meldinger': { component: () => <Profile viewName="conversations" /> },
  '/profil/overvakninger': { component: () => <Profile viewName="subscriptions" /> },
  '/profil/mine-biler/:id': {
    component: ({ match }) => <Profile viewName="my-car" id={match.params.id} />,
  },
  '/profil/mine-biler': { component: () => <Profile viewName="my-cars" /> },
  '/profil': { component: () => <Profile viewName="profile" /> },
  '/delat/bevakningslista/:id': {
    component: ({ match }) => <SharedSubscriptionList id={match.params.id!} />,
  },
  '/kundeservice/:category/:slug': {
    component: ({ match }) => <Faq category={match.params.category} slug={match.params.slug} />,
  },
  '/kundeservice/:category': { component: ({ match }) => <Faq category={match.params.category} /> },
  '/kundeservice': { component: () => <CustomerService /> },
  '/liveshopping/:slug': {
    component: ({ match }) =>
      getMarket().marketCode === 'SE' ? (
        <LiveShoppingSingle slug={match.params.slug!} />
      ) : (
        <ErrorView />
      ),
  },
  '/forfattere/:slug': {
    component: ({ match }) => <AuthorPage slug={match.params.slug!} />,
  },
  '/:parent/:child': {
    component: ({ match }) => <ContentPageSelector slug={match.params.child!} />,
  },
  '/:parent': { component: ({ match }) => <ContentPageSelector slug={match.params.parent!} /> },
};

export const routes = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return routesNO;
    default:
      return routesSE;
  }
};
