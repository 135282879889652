import { RCV_ROUTING } from '../actions/routing';

import { EVENT_INIT, type EVENT_INIT_TYPE } from '../actions/event';

import { RCV_RETAILER, REQ_CLEAR_RETAILER } from '../actions/retailer';

import { RCV_ITEM, REQ_CLEAR } from '../actions/item';

import type { DocumentList, ElasticSearchResponse } from '../@types/ElasticSearchResponse';
import type { Retailer } from '../@types/Retailer';
import type Item from '../@types/item';
import type { Actions } from '../actions';
import { events, publish } from '../utils/pubsub';

interface PubsubRetailer {
  id: string;
  name: string;
}

interface PubsubVehicle {
  id: string;
  title: string;
}

interface PubsubState {
  route: string;
  retailer: PubsubRetailer | null;
  item: PubsubVehicle | null;
}

export const initialState: PubsubState = {
  route: '/',
  retailer: null,
  item: null,
};

interface RetailerData {
  documentList: DocumentList<Retailer>;
}

const extractRetailer = (data?: RetailerData): PubsubRetailer | null => {
  if (
    data &&
    data.documentList &&
    data.documentList.documents &&
    data.documentList.documents.length === 1
  ) {
    const document = data.documentList.documents[0];

    return {
      id: document.id,
      name: document.name,
    };
  }

  return null;
};

interface ItemData {
  documentList: DocumentList<Item>;
}

const extractItem = (data?: ItemData): PubsubVehicle | null => {
  if (
    data &&
    data.documentList &&
    data.documentList.documents &&
    data.documentList.documents.length === 1
  ) {
    const document = data.documentList.documents[0];

    return {
      id: document.id,
      title: document.title,
    };
  }

  return null;
};

const publishAppInit = (state: PubsubState, data: EVENT_INIT_TYPE) => {
  const nextState = { ...state };

  if (data.route) {
    publish(events.RouteChange as 'routechange', { next: data.route, previous: null });
    nextState.route = data.route;
  }

  if (data.retailer) {
    const retailer = extractRetailer({ documentList: data.retailer });
    publish(events.RetailerChange as 'retailerchange', { next: retailer, previous: null });
    nextState.retailer = retailer;
  }

  if (data.item) {
    const item = extractItem({ documentList: data.item });
    publish(events.AdChange as 'adchange', { next: item, previous: null });
    nextState.item = item;
  }

  return nextState;
};

const publishRouteChange = (state: PubsubState, to: string) => {
  if (state.route !== to && to) {
    publish(events.RouteChange as 'routechange', { next: to, previous: state.route });
    return { ...state, route: to };
  }

  return state;
};

const publishRetailerChange = (state: PubsubState, data?: ElasticSearchResponse<Retailer>) => {
  const retailer = extractRetailer(data);

  if (
    (state.retailer && !retailer) ||
    (!state.retailer && retailer) ||
    (state.retailer && retailer && state.retailer?.id !== retailer.id)
  ) {
    publish(events.RetailerChange as 'retailerchange', {
      next: retailer,
      previous: state.retailer,
    });
  }

  return { ...state, retailer };
};

const publishAdChange = (state: PubsubState, data?: ElasticSearchResponse<Item>) => {
  const item = extractItem(data);

  if (
    (state.item && !item) ||
    (!state.item && item) ||
    (state.item && item && state.item.id !== item.id)
  ) {
    publish(events.AdChange as 'adchange', { next: item, previous: state.item });
  }

  return { ...state, item };
};

const pubsubReducer = (state: PubsubState = initialState, action: Actions): PubsubState => {
  let nextState = state;

  switch (action.type) {
    case EVENT_INIT:
      nextState = publishAppInit(state, action);
      return nextState;
    case RCV_ROUTING:
      nextState = publishRouteChange(state, action.path);
      return nextState;
    case RCV_RETAILER:
      nextState = publishRetailerChange(state, action?.response);
      return nextState;
    case REQ_CLEAR_RETAILER:
      nextState = publishRetailerChange(state);
      return nextState;
    case RCV_ITEM:
      nextState = publishAdChange(state, action?.response);
      return nextState;
    case REQ_CLEAR:
      nextState = publishAdChange(state);
      return nextState;
    default:
      return nextState;
  }
};

export default pubsubReducer;
