import { combineReducers } from '@reduxjs/toolkit';
import action from './action';
import assignmentDrafts from './assignmentDrafts';
import assignments from './assignments';
import auth from './auth';
import brandings from './brandings';
import contentmodules from './content-modules';
import contentpages from './contentpages';
import conversations from './conversations';
import creditApplication from './credit-application';
import currentFinancialInformation from './current-financial-information';
import defaultFinancialInformation from './default-financial-information';
import events from './events';
import faq from './faq';
import filters from './filters';
import geo from './geo';
import history from './history';
import insurances from './insurances';
import item from './item';
import lead from './lead';
import list from './list';
import liveshopping from './liveshopping';
import media from './media';
import messages from './messages';
import missingItem from './missing-item';
import pubsub from './pubsub';
import retailers from './retailers';
import routing from './routing';
import safetyAddons from './safety-addons';
import search from './search';
import settings from './settings';
import sharedUserContent from './shared-user-content';
import subscriptions from './subscriptions';
import vehicles from './vehicles';
import vimeo from './vimeo';

const rootReducer = combineReducers({
  action,
  routing,
  events,
  auth,
  settings,
  search,
  item,
  filters,
  retailers,
  conversations,
  messages,
  media,
  assignments,
  geo,
  faq,
  history,
  subscriptions,
  contentmodules,
  contentpages,
  insurances,
  brandings,
  vehicles,
  pubsub,
  safetyAddons,
  creditApplication,
  lead,
  vimeo,
  list,
  sharedUserContent,
  defaultFinancialInformation,
  currentFinancialInformation,
  assignmentDrafts,
  missingItem,
  liveshopping,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
