import React from 'react';

import { useTranslation } from 'react-i18next';
import type { MarketCode } from '../../@types/MarketCode';
import useHeaderVariant from '../../hooks/useHeaderVariant';
import { useSuspensLoader } from '../../providers/SuspenseLoaderProvider';
import getHeaderLinks from '../../shared/header-links';
import { getWordmarkLogotype } from '../../shared/logotype';
import Header from '../../styled-components/components/Header';
import { PageLoader } from '../../styled-components/components/PageLoader';
import { PortalElement, PortalNamespace } from '../../styled-components/components/Portal';
import { Root, RootMain } from '../../styled-components/components/Root';
import { SrOnly } from '../../styled-components/components/SrOnly';
import RenderClientSide from '../render/render-client-side';
import Footer from './footer';
import Imbox from './imbox';
import Middlewares from './middlewares';

interface AppProps {
  market: MarketCode;
  callback?: (element: HTMLDivElement | null) => void;
  children: React.ReactNode;
}

const App = ({ callback, children, market }: AppProps) => {
  const headerLinks = getHeaderLinks();
  const headerVariant = useHeaderVariant();
  const { loading } = useSuspensLoader();
  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <PageLoader>
          <SrOnly>{t('loading', { ns: 'other' })}</SrOnly>
        </PageLoader>
      )}
      <PortalElement id={PortalNamespace.DefaultPortal} />
      <Root ref={callback}>
        <Header
          variant={headerVariant}
          logo={getWordmarkLogotype({
            market,
          })}
          {...headerLinks}
        />
        <Middlewares />
        <RootMain>{children}</RootMain>
        <Footer />
        <RenderClientSide>
          <Imbox />
        </RenderClientSide>
      </Root>
    </>
  );
};

export default App;
