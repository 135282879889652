import type { QueryClient } from '@tanstack/react-query';
import type { Article } from '../../@types/ArticleV2';
import type { ElasticSearchResponse } from '../../@types/ElasticSearchResponse';
import type { ApiResponse } from '../../Http';
import type { SSR_NEW } from '../../QueryClientSetup';
import { ARTICLE_QUERY } from './QueryKeys';

const registerSsrArticle = (queryClient: QueryClient, serverState: SSR_NEW) => {
  const state = serverState.articles?.singleResult?.documentList;
  const initialData: ApiResponse<ElasticSearchResponse<Article>> | undefined = state
    ? {
        response: {
          facets: [],
          documentList: {
            ...state,
          },
        },
        successful: true,
      }
    : undefined;

  if (initialData) {
    const category = initialData.response.documentList.documents?.[0]?.categories?.[0].slug;
    const slug = initialData.response.documentList.documents?.[0]?.slug;
    queryClient.setQueryData([ARTICLE_QUERY, category, slug], initialData);
  }

  return queryClient;
};

export default registerSsrArticle;
