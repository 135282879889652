import type { QueryClient } from '@tanstack/react-query';
import type { Article } from '../../@types/ArticleV2';
import type { ElasticSearchResponse } from '../../@types/ElasticSearchResponse';
import type { ApiResponse } from '../../Http';
import type { SSR_NEW } from '../../QueryClientSetup';
import { ARTICLES_QUERY } from './QueryKeys';

const registerSsrArticlesSearch = (
  queryClient: QueryClient,
  serverState: SSR_NEW,
  search: string,
) => {
  const state = serverState.articles?.result?.documentList;
  const initialData: ApiResponse<ElasticSearchResponse<Article>> | undefined = state
    ? {
        response: {
          facets: [],
          documentList: {
            ...state,
          },
        },
        successful: true,
      }
    : undefined;

  if (initialData) {
    queryClient.setQueryData([ARTICLES_QUERY, search?.replace('?', '') || ''], initialData);
  }

  return queryClient;
};

export default registerSsrArticlesSearch;
