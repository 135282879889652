import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
/* eslint-disable no-warning-comments */
import { settings } from '../helpers';
import { getMarket } from '../i18n';

const resolveCustomerServiceRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return '/kundeservice';
    default:
      return '/kundservice';
  }
};

const resolveAboutRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return '/om-drive';
    default:
      return '/om-wayke';
  }
};

const resolvePressRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return '/presse-og-annonsering';
    default:
      return '/press-och-annonsering';
  }
};

const resolveContactRoute = () => {
  const { marketCode } = getMarket();
  switch (marketCode) {
    case 'NO':
      return '/kontakt-oss';
    default:
      return '/kontakta-wayke';
  }
};

export interface FooterLink {
  displayName: string;
  url: string;
  externalLink?: boolean;
}

export interface FooterLinkSection {
  displayName: string;
  links: FooterLink[];
}

const footerLinks = () => {
  const { t } = useTranslation(['global', 'navigation']);
  const { marketCode } = getMarket();
  const showCareer = marketCode === 'SE';
  const showDealerLogin = marketCode === 'SE';
  const showContactPage = marketCode === 'NO';
  const showPressAndAdvertising = marketCode === 'SE';

  const links = useMemo(() => {
    const firstSection: FooterLinkSection = {
      displayName: t('brandName', { ns: 'global' }),
      links: [
        {
          displayName: t('About', { ns: 'navigation' }),
          url: resolveAboutRoute(),
        },
      ],
    };

    if (showPressAndAdvertising) {
      firstSection.links.push({
        displayName: t('Press & Advertising', { ns: 'navigation' }),
        url: resolvePressRoute(),
      });
    }

    if (showCareer) {
      firstSection.links.push({
        displayName: t('Career', { ns: 'navigation' }),
        url: 'https://jobs.wayke.se',
        externalLink: true,
      });
    }

    const secondSection: FooterLinkSection = {
      displayName: t('Contact and help', { ns: 'navigation' }),
      links: [
        {
          displayName: t('Customer support', { ns: 'navigation' }),
          url: resolveCustomerServiceRoute(),
        },
      ],
    };

    if (showContactPage) {
      secondSection.links.push({
        displayName: t('Contact', { ns: 'navigation' }),
        url: resolveContactRoute(),
      });
    }

    const thirdSection: FooterLinkSection = {
      displayName: t('For companies', { ns: 'navigation' }),
      links: [],
    };

    if (showDealerLogin) {
      thirdSection.links.push({
        displayName: t('Dealer log in', { ns: 'navigation' }),
        url: settings.get('DealerUiUri'),
        externalLink: true,
      });
    }

    thirdSection.links.push({
      displayName: t('SitePartner', { ns: 'navigation' }),
      url: settings.get('partnerSiteUri'),
      externalLink: true,
    });

    return [firstSection, secondSection, thirdSection];
  }, [marketCode, t]);

  return links;
};

export default footerLinks;
